import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "theme-ui";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import FeaturesInDots from "@solid-ui-blocks/FeaturesWithPhoto/FeaturesInDots";
import Tabs from "@solid-ui-components/Tabs";
import Features from "@solid-ui-blocks/FeaturesWithPhoto/Block10";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import AppWrapper from '@helpers/appContext';
import theme from "./_theme";

const Developers = (props) => {
  const { selectedLanguage } = props;
  const { allBlockContent } = useStaticQuery(query);

  const content = normalizeBlockContentNodes(allBlockContent?.nodes);
  const tabs = content[`tab-features.${selectedLanguage}`];

  return (
    <Layout theme={theme} {...props}>
      <Seo title="Developers" />

      <Divider space="5" />
      <Divider space="5" />

      <Container variant="wide">
        <Tabs space={4}>
          {tabs.collection?.map((data, index) => (
            // TODO: use identifier for correct matching
            // currently identifier not working that's why using kicker text as condition
            ['description', '説明'].includes(data?.kicker?.text?.toLowerCase()) ?
              <Container key={`item-${index}`} variant="full" content={data}>
                <FeaturesInDots content={data} />
              </Container>
              :
              <Features key={`item-${index}`} content={data} />
          ))}
        </Tabs>
      </Container>

      <Divider space="5" />

    </Layout>
  );
};

const query = graphql`
  query siteDevelopersBlockContent {
    allBlockContent(
      filter: {
        page: {
          in: [
            "site/developers/description"
            "site/developers/tabs"
            "site/shared/book-demo"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

const DevelopersPage = (props) => (
  <AppWrapper>
    <Developers {...props}/>
  </AppWrapper>
);

export default DevelopersPage;
import React from "react";
import { Container, Box } from "theme-ui";
import Reveal from "@solid-ui-components/Reveal";
import Divider from "@solid-ui-components/Divider";
import ContentText from '@solid-ui-components/ContentText';
import StackTitle from "@solid-ui-components/StackTitle";
import ContentButtons from "@solid-ui-components/ContentButtons";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import Blog from "@solid-ui-blocks/Blog/Block02";

const FeaturesWithPhotoBlock09 = ({
  content: { kicker, title, text_1, text_2, buttons, collection },
}) => (
  <Container>
    <Reveal effect='fadeInLeft'>
        {kicker?.show &&
          <>
            <StackTitle
              kicker={kicker}
              title={title}
              lines={[text_2]}
              align='center'
              space={1}
            />
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                '.button-group-link': {
                    textDecoration: 'underline'
                },
                '.button-group-link:hover': {
                  textDecoration: 'none'
                },
                fontWeight: 'bold',
                fontSize: "1rem",
            }}>
                <ContentButtons content={buttons} />
                <ContentText variant="h6" content={text_1} sx={{ ml: 1, mt: '2px' }} />  
            </Box>
            <Divider space="3" />
          </>
        }
    </Reveal>
    <Reveal effect="fadeInDeepLeft" duration={1}>
      <Blog content={{ collection }} columns={[1, 2, 3]} titleSX={{ flex: ['0', '0', 'none']}} />
    </Reveal>
  </Container>
);

export default WithDefaultContent(FeaturesWithPhotoBlock09);

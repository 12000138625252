import React from "react";
import { Container, Flex, Box, Heading } from "theme-ui";
import ContentText from "@solid-ui-components/ContentText";
import Reveal from "@solid-ui-components/Reveal";
import StackTitle from "@solid-ui-components/StackTitle";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import Divider from "@solid-ui-components/Divider";
import DotPic from "../../assets/dotPic.png";

const styles = {
  listItem: {
    alignItems: `stretch`,
    justifyContent: `flex-start`,
  },
};

const FeaturesInDots = ({
  content: { kicker, title, text_1, collection }
}) => (
  <Container>
    <Reveal effect="fadeInLeft" threshold={0.7} duration={1.5}>
      <StackTitle
        kicker={kicker}
        title={title}
        lines={[text_1]}
        align="left"
        space={0}
      />
      <Divider space="3" />
      {collection?.map(({ text_1 }, index) => (
        <Flex key={`item-${index}`} sx={styles.listItem}>
          <Box sx={{ position: `relative`, flexShrink: 0, mr: 4 }}>
            <Heading variant="h4" as="div" color="white">
              <img src={DotPic} alt="dot" />
            </Heading>
          </Box>
          <Box>
            <ContentText
              content={text_1}
              sx={{ maxWidth: 'auto' }}
              mb="4"
            />
          </Box>
        </Flex>
      ))}
    </Reveal>
  </Container>
)
export default WithDefaultContent(FeaturesInDots);
